const heic2any = require("heic2any");
const Tiff = require('tiff.js');

const MAIL_PATTERN = /^[a-zA-Z0-9_+-]+(.[a-zA-Z0-9_+-]+)*@([a-zA-Z0-9][a-zA-Z0-9-]*[a-zA-Z0-9]*\.)+[a-zA-Z]{2,}$/;
const PASSWORD_PATTERN = /([0-9].*[a-zA-Z]|[a-zA-Z].*[0-9])/;
const SALON_URL = $("#salon_domain").val();
const SALON_PATH_NAMES = ['/detail', '/service', '/counselors', '/counselor_detail', '/photo', '/review'];
const ERROR_MSG = "エラー！　処理を完了できませんでした\n時間おいて再度実行しても、このエラーが修正されない場合は\nお手数ですがサポートチームへお問い合わせください"
const Device = require('@twilio/voice-sdk').Device;

$(document).ready(function() {

    //############################################################
    // フォーカス
    //############################################################
    if (location.pathname == "/myshiru/login" || location.pathname == "/myshiru/f/login") {
        $('input[type=\'text\']:visible').eq(0).focus();
        $(document).keypress(function (e) {
            if (e.keyCode == 13) {
                $('#login').click();
                return false;
            }
        });
    };

    //############################################################
    // サロンページの処理
    //############################################################
    let pn = location.pathname;
    if (
        pn.search(/^\/myshiru\/(f\/)?detail(\/\d+\/?)?$/) !== -1 ||
        pn.search(/^\/myshiru\/(f\/)?service(\/\d+\/?)?$/) !== -1 ||
        pn.search(/^\/myshiru\/(f\/)?counselors(\/\d+\/?)?$/) !== -1 ||
        pn.search(/^\/myshiru\/(f\/)?counselor_detail(\/\d+\/?)?$/) !== -1 ||
        pn.search(/^\/myshiru\/(f\/)?photo(\/\d+\/?)?$/) !== -1 ||
        pn.search(/^\/myshiru\/(f\/)?review(\/\d+\/?)?$/) !== -1 ||
        pn.search(/^\/myshiru\/(f\/)?salon_inquiry(\/\d+\/?)?$/) !== -1
    ) {
        //############################################################
        // メニュークリック
        //############################################################
        $(".menu_link").on("click", function () {
            location.href = "/myshiru/" + $(this).data("val") + "/" + $("#salon_id").val() + "/" + location.search;
        })
        $(".to_counselor").on("click", function () {
            location.href = "/myshiru/counselor_detail/" + $("#salon_id").val() + "/" + $(this).data("counselor") + "/" + location.search;
        })
        $(".to_counselors").on("click", function () {
            location.href = "/myshiru/counselors/" + $("#salon_id").val() + "/" + location.search;
        })
        $(".to_counselor_index").on("click", function () {
            location.href = "/myshiru/counselors/" + $("#salon_id").val() + "/" + location.search;
        })
        $(".to_review").on("click", function () {
            location.href = "/myshiru/review/" + $("#salon_id").val() + "/" + location.search;
        })
        //############################################################
        // 新規ユーザか判定
        //############################################################
        if (location.search.indexOf("preview=") == -1) {
            let new_user = 0;
            let visit_salons = localStorage.getItem("visit_salon");
            if (visit_salons == null || visit_salons == "[null]") {
                new_user = 1;
            } else {
                if (!(visit_salons.includes($("#salon_id").val()))) {
                    new_user = 1;
                }
            }
            if (new_user == 1) {
                $.ajax({
                    url: "/myshiru/f/user_count",
                    type: "POST",
                    data: {
                        salon_id: $("#salon_id").val()
                    },
                    dataType: "json"
                })
                    .done(function (data) {
                    })
            }
        }

        //############################################################
        // ローカルストレージに履歴をセット
        //############################################################
        function set_history(func, max) {
            let visit_history = localStorage.getItem("visit_" + func);
            let history_array;
            if (visit_history == null || visit_history == "[null]") {
                history_array = new Array($("#salon_id").val())
                localStorage.setItem("visit_" + func, JSON.stringify(history_array));
            } else {
                history_array = JSON.parse(visit_history);
                if ((history_array.includes($("#salon_id").val()))) {
                    let salon_index = history_array.indexOf($("#salon_id").val());
                    history_array.splice(salon_index, 1)
                }
                if (history_array.length >= max) {
                    history_array.shift();
                }
                history_array.push($("#salon_id").val());
                localStorage.setItem("visit_" + func, JSON.stringify(history_array));
            }
        }

        // 訪問履歴用
        set_history("history", 5);
        // 新規ユーザ判定用
        set_history("salon", 1000);
    }

    //今すぐ電話鑑定のサービス詳細ページなら、urlから余分なパラメータを削除
    // if (location.pathname == "/service_detail" ) {
    //     salonId = $('#salon_id').val();
    //     location.search = "?id="+salonId;
    // }
    //############################################################
    // 訪問履歴取得
    //############################################################
    if (location.pathname == "/myshiru/index" || location.pathname == "/myshiru/f/index" || location.pathname == "/myshiru/") {
        let visit_history = localStorage.getItem("visit_history");
        if (visit_history != null) {
            $.ajax({
                url: "/myshiru/f/get_salon_history",
                type: "POST",
                data: {
                    ids: visit_history
                },
                dataType: "json"
            })
                .done(function (data) {
                    if (data.ret == 0) {
                        $(data.salons).each(function (i, o) {
                            $("#visit_name" + i).html(o.sname);
                            $("#visit_link" + i).attr("href", "/detail/" + o.sid + "/")
                            if (!(o.prefecture == "" || o.prefecture == null || o.prefecture == 0) && !(o.salon_class == 2 || o.salon_class == 3)) {
                                $("#visit_city" + i).html(o.prefecture + " " + o.city + " ");
                            } else {
                                $("#visit_city" + i).fadeOut(0);
                            }
                            if (o.cname != null) {
                                $("#visit_cls" + i).html(o.cname);
                            }
                            if (data.images[i] != "") {
                                $("#visit_img" + i).attr("src", SALON_URL + "/img/thumbs/" + o.sid + "/" + o.img_dir + "/" + data.images[i]);
                            } else {
                                $("#visit_img" + i).fadeOut(0);
                                $("#no_img" + i).fadeIn(0);
                            }

                            $("#visit" + i).fadeIn(0);
                        })
                        if (data.salons.length == 0) {
                            $("#no_visit").fadeIn(0);
                        }
                    }
                })
        } else {
            $("#no_visit").fadeIn(0);
        }

        //############################################################
        // キャンペーンをストレージにセット
        //############################################################
        function getParam(name) {
            let url = window.location.href;
            name = name.replace(/[\[\]]/g, "\\$&");
            let regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
                results = regex.exec(url);
            if (!results) return null;
            if (!results[2]) return '';
            return decodeURIComponent(results[2].replace(/\+/g, " "));
        }
        let myshiru_ca = JSON.parse(localStorage.getItem("myshiru_ca"));
        if (!myshiru_ca) {
            let ca = getParam("ca");
            myshiru_ca = {"ca": `${ca}`, "landing": 1, "user_cv": 0, "point_cv": 0, "service_cv": 0};
            localStorage.setItem("myshiru_ca", JSON.stringify(myshiru_ca));
        }
    }

    if (location.pathname == "/myshiru/regist" || location.pathname == "/myshiru/f/regist") {
        //############################################################
        // ユーザ登録CVを記録
        //############################################################
        let myshiru_ca = JSON.parse(localStorage.getItem("myshiru_ca"));
        if (myshiru_ca) {
            if(myshiru_ca.user_cv=="0"){
                $.ajax({
                    url: "/myshiru/f/set_cv",
                    type: "POST",
                    data: {
                        ca: myshiru_ca.ca,
                        user_cv:1
                    },
                    dataType: "json"
                })
                    .done(function (data) {
                        myshiru_ca.user_cv = 1;
                        localStorage.setItem("myshiru_ca", JSON.stringify(myshiru_ca));
                    })
            }
        }
    }

    //############################################################
    // サムネイル表示
    //############################################################
    if (pn.search(/^\/myshiru\/(f\/)?detail(\/\d+\/?)?$/) !== -1 || pn.match('/page/')!=null) {
        $('.slider').slick({
            fade: true,
            asNavFor: ".thumbnail",
        });
        $(".thumbnail").slick({
            slidesToShow: 8, // サムネイルの表示数
            asNavFor: ".slider", // メイン画像と同期
            focusOnSelect: true, // サムネイルクリックを有効化
        });
    }

    //############################################################
    // サロン検索結果一覧
    //############################################################
    $('.to_salon').hover(
        function () {
            var val = $(this).attr("id").split("_")[1];
            $("#name_" + val).css("color", "#3771E0");
            $("#name_" + val).css("text-decoration", "underline");
            $(this).css("cursor", "pointer");
        },
        function () {
            var val = $(this).attr("id").split("_")[1];
            $("#name_" + val).css("color", "#ff69b4");
            $("#name_" + val).css("text-decoration", "none");
            $(this).css("cursor", "auto");
        }
    );
    $(".to_salon").on("click", function () {
        var salon_url = "/myshiru/detail/" + $(this).attr("id").split("_")[1] + "/";
        if (location.pathname == "/myshiru/user/index" || location.pathname == "/myshiru/user/bookmarks") {
            window.open(salon_url, '_blank');
        } else {
            location.href = salon_url;
        }
    })
    $('.sub_img').hover(
        function () {
            $(this).css("border", "solid 1px #FFB000");
            var my_id = $(this).attr("id").split("_")[2];
            $("#img_" + my_id).attr("src", ($(this).attr("src")).replace('/thumbs',''));
        },
        function () {
            $(this).css("border", "solid 1px #f6f6f6");
        }
    );

    //############################################################
    // サロン詳細検索
    //############################################################
    $(".detail_search").on("click", function () {
        var href = "/myshiru/list?f=1";
        if ($("#salon_class").val() != "") {
            href += "&salon_class=" + $("#salon_class").val();
        }
        if ($("#real").val() != "") {
            href += "&real=" + $("#real").val();
        }
        if ($("#prefecture").val() != "") {
            href += "&prefecture=" + $("#prefecture").val();
        }
        if ($("#city").val() != "") {
            href += "&city=" + $("#city").val();
        }
        if ($("#station").val() != "") {
            href += "&station=" + $("#station").val();
        }
        if ($("#budget").val() != "") {
            href += "&budget=" + $("#budget").val();
        }
        if ($("#private_room").prop("checked")) {
            href += "&private_room=" + $("#private_room:checked").val();
        }
        if ($("#remote").prop("checked")) {
            href += "&remote=" + $("#remote:checked").val();
        }
        href += "&keyword=";
        let keywords = [];
        $(".keyword").each(function (i, o) {
            if ($(o).prop("checked")) {
                keywords.push($(o).attr("id").split("_")[1]);
            }
        })
        href += keywords;
        location.href = href.replace(/\s+/g, "");
    })

    //############################################################
    // ニュースクリック
    //############################################################
    $(".news_link").on("click", function () {
        location.href = "/myshiru/news/" + $(this).data("val") + "/";
    })

    //############################################################
    // サロン検索
    //############################################################
    $("#search").on("click", function () {
        keywordSearch();
    })
    $("input#keyword").keydown(function(e){
        if (e.keyCode === 13) {
            keywordSearch();
        }
    })
    function keywordSearch() {
        let area = '';
        let word = '';
        if ($("#area").length) { area = $("#area").val(); }
        if ($("#keyword").length) { word = $("#keyword").val(); }
        location.href = "/myshiru/list?a=" + area + "&k=" + word;
    }

    //############################################################
    // ページングクリック検索
    //############################################################
    $(".list_page").on("click", function () {
        location.href = "/myshiru/list" + location.search + "&p=" + $(this).data("val");
    })

    //############################################################
    // 電話クリック
    //############################################################
    $("#open_tel").on("click", function () {
        // 背景
        $('body').append('<div id=\'lightbox_back\'></div>');
        var bg = $("#lightbox_back")
        bg.ready(function () {
            bg.css({
                'background-color': 'black',
                'opacity': '0.5',
                'position': 'fixed',
                'top': '0',
                'left': '0',
                'width': $(window).width(),
                'height': $(window).height(),
                'z-index': '2000000',
                'display': 'none'
            });
            bg.fadeIn(200);
        })
        var tel_box = $("#tel_box")
        tel_box.ready(function () {
            tel_box.css({
                'position': 'fixed',
                'top': $(window).height() / 2 - 80,
                'left': $(window).width() / 2 - 230,
                'z-index': '3000000',
                'width': '460',
                'border': '1px solid #d0d0d0'
            });
            tel_box.fadeIn(350);
            bg.on("click", function () {
                bg.remove();
                tel_box.fadeOut(50);
            });
        })
        $(window).resize(function () {
            bg.css({
                'width': $(window).width(),
                'height': $(window).height()
            });
            tel_box.css({
                'top': $(window).height() / 2 - 80,
                'left': $(window).width() / 2 - 230
            });
        });
        $(".tel_box_close").on("click", function () {
            bg.remove();
            tel_box.fadeOut(50);
        })
        tel_count();
    });
    //############################################################
    // スマホの電話クリック
    //############################################################
    $("#sp_tel").on("click", function () {
        tel_count();
    })
    //############################################################
    // 電話クリックの計測処理
    //############################################################
    function tel_count() {
        window.setTimeout(function () {
            // 24時間以内にクリックがあるか
            let tclick_key = "tclick_" + $("#salon_id").val()
            let tclick = localStorage.getItem(tclick_key);
            let tclick_flg = 0;
            if (tclick == null || tclick == "[null]") {
                tclick_flg = 1;
            } else {
                if ((new Date() - new Date(tclick) > 86400000)) {
                    tclick_flg = 1;
                }
            }
            if (tclick_flg == 1 && location.search.indexOf("preview=") == -1) {
                $.ajax({
                    url: "/myshiru/f/tel_click",
                    type: "POST",
                    data: {
                        salon_id: $("#salon_id").val()
                    },
                    dataType: "json"
                })
                    .done(function (data) {
                        localStorage.setItem(tclick_key, new Date());
                    })
            }
        }, 5000);
    }

    //############################################################
    // お問合せ送信
    //############################################################
    $(".send_salon_inquiry").on("click", function () {
        if ($(this).attr("id") == "confirm_salon_inquiry") {
            var path = "set_data";
            if ($("#mail").val() == "") {
                alert("メールアドレスを入力してください");
                return;
            }
            if (!$("#mail").val().match(MAIL_PATTERN)) {
                alert("メールアドレスを正しく入力してください");
                return;
            }
            if ($("#text").val() == "") {
                alert("お問合せ内容を入力してください");
                return;
            }
        } else if ($(this).attr("id") == "send_salon_inquiry") {
            var path = "send_salon_inquiry";
        }
        $(".send_salon_inquiry").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/f/" + path,
            type: "POST",
            data: {
                func: "salon_inquiry",
                id:   $("#salon_id").val(),
                mail: $("#mail").val(),
                text: $("#text").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (path == "set_data") {
                    location.href = "/myshiru/salon_inquiry/"  + $("#salon_id").val() + "/?c=1";
                } else if (path == "send_salon_inquiry") {
                    location.href = "/myshiru/salon_inquiry/"  + $("#salon_id").val() + "/?f=1";
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".send_inquiry").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // ライトボックス処理
    //############################################################
    $(".lightbox").on("click", function () {
        // 背景
        $('body').append('<div id=\'lightbox_back\'></div>');
        var bg = $("#lightbox_back")
        bg.ready(function () {
            bg.css({
                'background-color': 'black',
                'opacity': '0.5',
                'position': 'fixed',
                'top': '0',
                'left': '0',
                'width': $(window).width(),
                'height': $(window).height(),
                'z-index': '2000000',
                'display': 'none'
            });
            bg.fadeIn(200);
        })
        // 画像
        $('body').prepend("<img id='lightbox_img' src='" + $(this).attr("src") + "'>");
        var lightbox_img = $("#lightbox_img")
        lightbox_img.ready(function () {
            lightbox_img.css({
                'position': 'fixed',
                'top': $(window).height() / 2 - 120,
                'left': $(window).width() / 2 - 180,
                'z-index': '3000000',
                'width': '360',
                'border': '1px solid #d0d0d0'
            });
            lightbox_img.fadeIn(350);
            bg.on("click", function () {
                bg.remove();
                lightbox_img.remove();
            });
        })
        $(window).resize(function () {
            bg.css({
                'width': $(window).width(),
                'height': $(window).height()
            });
            lightbox_img.css({
                'top': $(window).height() / 2 - 120,
                'left': $(window).width() / 2 - 180
            });
        });
    });

    //############################################################
    // ブックマーク登録
    //############################################################
    $("#bookmark").on("click", function () {
        let val;
        $("#bookmark").css('pointer-events', 'none');
        if ($("#bookmark_val").val() == "0") {
            val = 1;
        } else {
            val = 0;
        }
        $.ajax({
            url: "/myshiru/user/bookmark_req",
            type: "POST",
            data: {
                salon_id: $("#salon_id").val(),
                bookmark: val
            },
            dataType: "json"
        })
            .done(function (data) {
                if (val == 1) {
                    $("#bookmark_off").fadeOut(0);
                    $("#bookmark_on").fadeIn(0);
                    $("#bookmark_val").val(1);
                    $("#bookmark_msg").fadeIn(200);
                    $(".bookmark_msg3").fadeOut(0);
                    $(".bookmark_msg1").fadeIn(200);
                    $(".bookmark_msg2").fadeIn(200);
                } else {
                    $("#bookmark_on").fadeOut(0);
                    $("#bookmark_off").fadeIn(0);
                    $("#bookmark_val").val(0);
                    $("#bookmark_msg").fadeIn(200);
                    $("#bookmark_msg").fadeIn(200);
                    $(".bookmark_msg1").fadeOut(0);
                    $(".bookmark_msg2").fadeIn(200);
                    $(".bookmark_msg3").fadeIn(200);
                }
                $("#bookmark").unbind('mouseenter mouseleave');
                $("#bookmark").css('pointer-events', 'auto');
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#bookmark").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // ブックマーク登録（service_detail）
    //############################################################
    $(".sd_bookmark").on("click", function () {
        $(this).css('pointer-events', 'none');
        alert($(this).data("salon"));
        alert($(this).data("val"));
        $.ajax({
            url: "/myshiru/user/bookmark_req",
            type: "POST",
            data: {
                salon_id: $(this).data("salon"),
                bookmark: $(this).data("val")
            },
            dataType: "json"
        })
            .done(function (data) {
                window.location.reload();
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".sd_bookmark").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // レビュー投稿
    //############################################################
    $(".create_review").on("click", function () {
        let req = $(this).attr("id")
        let path = "";
        if (req == "confirm_review") {
            if ($("#visit_date").val() == "") {
                alert("訪問日を入力してください");
                return;
            }
            if ($("#review_title").val() == "") {
                alert("タイトルを入力してください");
                return;
            }
            if ($("#comment").val() == "") {
                alert("本文を入力してください");
                return;
            }
            if ($("#comment").val().length <= 50) {
                alert("本文は50文字以上入力する必要があります");
                return;
            }
            if ($("#comment").val().length > 400) {
                alert("内容は400文字以内で入力してください");
                return;
            }
            path = "/myshiru/f/set_data"
        } else if (req == "create_review") {
            path = "/myshiru/user/create_review"
            if (!confirm("この内容で口コミを投稿しますか？")) {
                return;
            }
        }
        $(".create_review").css('pointer-events', 'none');
        $.ajax({
            url: path,
            type: "POST",
            data: {
                func: "review",
                visit_date: $("#visit_date").val(),
                counselor_id: $("#counselor").val(),
                counselor_name: $('#counselor option:selected').text(),
                title: $("#review_title").val(),
                comment: $("#comment").val(),
                salon_id: $("#salon_id").val(),
                review_id: $("#review_id").val(),
                score1: $("#score1").val(),
                score2: $("#score2").val(),
                score3: $("#score3").val(),
                score4: $("#score4").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    if (req == "confirm_review") {
                        path = "/myshiru/new_review?id=" + $("#salon_id").val() + "&f=1"
                        location.href = path;
                    } else if (req == "create_review") {
                        path = "/myshiru/success?f=5"
                        location.href = path;
                    }
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".create_review").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // レビュー下書き
    //############################################################
    $("#draft_review").on("click", function () {

        if ($("#review_title").val() == "") {
            alert("タイトルを入力してください");
            return;
        }
        if ($("#comment").val() == "") {
            alert("本文を入力してください");
            return;
        }
        $("#draft_review").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/user/draft_review",
            type: "POST",
            data: {
                visit_date: $("#visit_date").val(),
                counselor_id: $("#counselor").val(),
                counselor_name: $('#counselor option:selected').text(),
                title: $("#review_title").val(),
                comment: $("#comment").val(),
                salon_id: $("#salon_id").val(),
                review_id: $("#review_id").val(),
                score1: $("#score1").val(),
                score2: $("#score2").val(),
                score3: $("#score3").val(),
                score4: $("#score4").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    $("#review_id").val(data.review_id);
                    path = "/myshiru/success?f=7"
                    location.href = path;
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#draft_review").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // FAQ クリック
    //############################################################
    $(".faq").on("click", function () {
        var faq_no = $(this).attr("id").split("_")[1];
        var answer = $("#a_" + faq_no);
        if (answer.is(':visible')) {
            answer.slideUp(120);
            $(this).css("background-color", "white");
            $("#open_" + faq_no).fadeIn(30);
            $("#close_" + faq_no).fadeOut(0);
        } else {
            answer.slideDown(120);
            $(this).css("background-color", "#ffffe0");
            $("#open_" + faq_no).fadeOut(0);
            $("#close_" + faq_no).fadeIn(30);
        }
    })

    //############################################################
    // サロン仮登録リクエスト
    //############################################################
    $(".score").on("change blur input", function () {
        var score = $(this).val() * 1;
        if (score > 5) {
            score = 5;
        } else if (score < 1) {
            score = 1;
        }
        $(this).val(score.toFixed(1));
        var score_no = $(this).attr("id").slice(-1);
        $("#star" + score_no).attr("data-rate", score - (score % 0.5));

        // 小数点2位以下を切り捨て
        var total = 0;
        total += $("#score1").val() * 1;
        total += $("#score2").val() * 1;
        total += $("#score3").val() * 1;
        total += $("#score4").val() * 1;
        total = Math.floor(((total / 4).toFixed(2) * 10)) / 10;
        $("#total_score").html(total.toFixed(1));
        $("#total_star").attr("data-rate", total - (total % 0.5));
    })

    //############################################################
    // サロン仮登録リクエスト
    //############################################################
    $("#regist_salon").on("click", function () {

        if ($("#mail").val() == "") {
            alert("メールアドレスを入力してください");
            return;
        }
        if (!$("#mail").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return;
        }
        if (!confirm("メールアドレスを送信しますか？")) {
            return;
        }

        $("#regist_salon").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/f/salon_regist_req",
            type: "POST",
            data: {
                mail: $("#mail").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    location.href = "/myshiru/success";
                } else if (data.ret == 1) {
                    alert("登録できませんでした\n※ このメールアドレスは既に登録されています");
                    $("#regist_salon").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#regist_salon").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // ユーザ登録リクエスト
    //############################################################
    $(".user_regist").on("click", function () {
        $(".user_regist").css('pointer-events', 'none');
        let path = ""
        let flg = ""
        let gender = ""
        let req = $(this).attr("id")
        if (req == "user_regist") {
            path = "/myshiru/f/user_regist_req"
            gender = $("#gender").val();
        } else if (req == "user_confirm") {
            path = "/myshiru/f/set_data"
            if ($("#mail").val() == "") {
                $("#alert_01").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_01").fadeOut(0);
            }
            if (!$("#mail").val().match(MAIL_PATTERN)) {
                $("#alert_01").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_01").fadeOut(0);
            }
            if ($("#password").val() == "") {
                $("#alert_02").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_02").fadeOut(0);
            }
            if ($("#password").val().length < 8) {
                $("#alert_02").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_02").fadeOut(0);
            }
            if (!$("#password").val().match(PASSWORD_PATTERN)) {
                $("#alert_03").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_03").fadeOut(0);
            }
            if ($("#nick_name").val()=="" || $("#nick_name").val().trim()==""){
                $("#alert_00").fadeIn(100);
                flg = 1;
            }else{
                $("#alert_00").fadeOut(0);
            }
            if ($("#last_name").val() == "") {
                $("#alert_04").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_04").fadeOut(0);
            }
            if ($("#first_name").val() == "") {
                $("#alert_05").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_05").fadeOut(0);
            }
            if ($("#prefecture").val() == "") {
                $("#alert_06").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_06").fadeOut(0);
            }
            if ($("input[name='gender']:checked").val() == "") {
                $("#alert_07").fadeIn(100);
                flg = 1;
            } else {
                $("#alert_07").fadeOut(0);
            }
            gender = $("input[name='gender']:checked").val();
        }

        if (flg == 1) {
            alert("入力内容を確認してください");
            $('body, html').animate({scrollTop: 0}, 300, 'linear');
            $(".user_regist").css('pointer-events', 'auto');
            return;
        }

        $.ajax({
            url: path,
            type: "POST",
            data: {
                func: "regist",
                mail: $("#mail").val(),
                password: $("#password").val(),
                last_name: $("#last_name").val(),
                first_name: $("#first_name").val(),
                prefecture: $("#prefecture").val(),
                nick_name: $("#nick_name").val(),
                gender: gender
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    if (req == "user_regist") {
                        location.href = "/myshiru/success?f=2";
                    } else if (req == "user_confirm") {
                        location.href = "/myshiru/regist_confirm";
                    }
                } else if (data.ret == 1) {
                    alert("登録できませんでした\n※ このメールアドレスは既に登録されています");
                    $(".user_regist").css('pointer-events', 'auto');
                } else if (data.ret == 2) {
                    alert(data.message);
                    $(".user_regist").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".user_regist").css('pointer-events', 'auto');
            })
    });

    //############################################################
    // ログイン名重複チェック
    //############################################################
    function check_nick_name(event) {
        if($("#nick_name").val()==""){
            $("#nick_name_msg1").fadeOut(0);
            $("#nick_name_msg2").fadeOut(0);
            return false;
        }
        $.ajax({
            url: "/myshiru/user/check_nick_name_regist",
            type: "POST",
            data: {
                name:$("#nick_name").val()
            },
            dataType: "json"
        })
            .done(function(data) {
                if(data.ret==0){
                    $("#nick_name_msg2").fadeOut(0)
                    $("#nick_name_msg1").fadeIn(120)
                    $("#user_confirm").css('visibility', 'visible');
                }else if(data.ret==1){
                    $("#nick_name_msg1").fadeOut(0)
                    $("#nick_name_msg2").fadeIn(120)
                    $("#user_confirm").css('visibility', 'hidden');
                }
            })
    }
    $("#nick_name").keyup(function(event) {
        check_nick_name(event);
    })
    $("#nick_name").blur(function(event) {
        check_nick_name(event);
    })
    if (location.pathname == "/myshiru/regist_form" || location.pathname == "/myshiru/f/regist_form") {
        check_nick_name(event);
    };

    //############################################################
    // お問合せ送信（確認前）
    //############################################################
    $(".send_inquiry").on("click", function () {

        if ($(this).attr("id") == "set_inquiry") {
            var path = "set_data"
            if ($("#mail").val() == "") {
                alert("メールアドレスを入力してください");
                return;
            }
            if (!$("#mail").val().match(MAIL_PATTERN)) {
                alert("メールアドレスを正しく入力してください");
                return;
            }
            if ($("#text").val() == "") {
                alert("お問合せ内容を入力してください");
                return;
            }
        } else if ($(this).attr("id") == "send_inquiry") {
            var path = "send_inquiry"
        }
        $(".send_inquiry").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/f/" + path,
            type: "POST",
            data: {
                func: "inquiry",
                mail: $("#mail").val(),
                text: $("#text").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (path == "set_data") {
                    location.href = "/myshiru/inquiry?c=1";
                } else if (path == "send_inquiry") {
                    location.href = "/myshiru/success?f=1";
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(".send_inquiry").css('pointer-events', 'auto');
            })
    })

    //############################################################
    // ログイン
    //############################################################
    $("#login").on("click", function () {
        $("#login").css('pointer-events', 'none');
        let flg = 0;
        if ($("#mail").val() == "") {
            $("#alert_01").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_01").fadeOut(0);
        }

        if ($("#password").val() == "") {
            $("#alert_02").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_02").fadeOut(0);
        }

        if (flg == 1) {
            $('body, html').animate({scrollTop: 0}, 300, 'linear');
            $("#login").css('pointer-events', 'auto');
            return;
        }

        $.ajax({
            url: "/myshiru/f/login_req",
            type: "POST",
            data: {
                mail: $("#mail").val(),
                password: $("#password").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    location.href = "/myshiru/user/index";
                } else if (data.ret == 1) {
                    alert("パスワードまたはメールアドレスが違います");
                } else if (data.ret == 2) {
                    alert("パスワードの誤りが一定回数ありましたので\n一時的にログインをロックしました\nしばらくたってから、再度お試しください。");
                } else if (data.ret == 3) {
                    alert("このアカウントは仮登録状態です。\nお送りしたメールをご確認ください。");
                } else if (data.ret == 4) {
                    // SMS認証のモーダルを表示
                    $('#modal-title').text('マイシルからのお知らせ');
                    $('.verify-send-code').text('SMSに認証コードを送信');
                    $('.verify-check-code').text('認証');
                    $('.notice-sms-verify').fadeIn(0);
                    $('.verify-modal-number').fadeOut(0);
                    $('.verify-modal-code').fadeOut(0);
                    $('#modal-background').addClass('is-show');
                    $('#modal-close-button').off().on('click', function(){
                        $('#modal-background').removeClass('is-show');
                    });
                }
                $("#login").css('pointer-events', 'auto');
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#login").css('pointer-events', 'auto');
            })
    })



    //############################################################
    // メールアドレスにパスワードリセット用のURLを送信
    //############################################################
    $("#password_reset").on("click", function () {

        if ($("#mail").val() == "") {
            alert("メールアドレスの入力がありません");
            return false;
        }
        if (!$("#mail").val().match(MAIL_PATTERN)) {
            alert("メールアドレスを正しく入力してください");
            return false;
        }
        $("#password_reset").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/f/password_reset_req",
            type: "POST",
            data: {
                mail: $("#mail").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    location.href = "/myshiru/success?f=3";
                } else if (data.ret == 1) {
                    alert("メールアドレスが見つかりませんでした。");
                } else if (data.ret == 2) {
                    alert("該当のアドレスはパスワードリセットができません。SNSアカウントでログインしてください。");
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#password_reset").css('pointer-events', 'auto');
            })
    });

    //############################################################
    // パスワード再設定処理
    //############################################################
    $("#new_password").on("click", function () {

        if ($("#password").val() == "") {
            alert("パスワードを入力してください");
            return false;
        }
        if (!$("#password").val().match(PASSWORD_PATTERN)) {
            alert("パスワードは英数字混在で入力してください");
            return false;
        }
        if ($("#password").val().length < 8) {
            alert("パスワードは8文字以上で入力してください");
            return false;
        }
        if ($("#password_check").val() == "") {
            alert("パスワード(再確認)を入力してください");
            return false;
        }
        if ($("#password").val() != $("#password_check").val()) {
            alert("パスワード(再確認)の入力が正しくありません");
            return false;
        }
        $("#new_password").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/f/new_password_req",
            type: "POST",
            data: {
                password: $("#password").val(),
                code: $("#code").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    location.href = "/myshiru/success?f=4";
                } else {
                    alert(ERROR_MSG);
                    $("#new_password").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#new_password").css('pointer-events', 'auto');
            })
    });

    //############################################################
    // シングルサインオンからのユーザ情報登録
    //############################################################
    $("#sign_up").on("click", function () {
        $("#sign_up").css('pointer-events', 'none');

        //バリデーション
        let flg = 0;
        if ($("#mail").val() == "") {
            $("#alert_01").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_01").fadeOut(0);
        }
        if (!$("#mail").val().match(MAIL_PATTERN)) {
            $("#alert_01").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_01").fadeOut(0);
        }
        if ($("#nick_name").val()=="" || $("#nick_name").val().trim()==""){
            $("#alert_00").fadeIn(100);
            flg = 1;
        }else{
            $("#alert_00").fadeOut(0);
        }
        if ($("#last_name").val() == "") {
            $("#alert_04").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_04").fadeOut(0);
        }
        if ($("#first_name").val() == "") {
            $("#alert_05").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_05").fadeOut(0);
        }
        if ($("#prefecture").val() == "") {
            $("#alert_06").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_06").fadeOut(0);
        }
        if (!$("input[name='gender']:checked").length) {
            $("#alert_07").fadeIn(100);
            flg = 1;
        } else {
            $("#alert_07").fadeOut(0);
        }
        if (flg == 1) {
            $('body, html').animate({scrollTop: 0}, 300, 'linear');
            $("#sign_up").css('pointer-events', 'auto');
            return;
        }

        $.ajax({
            url: "/myshiru/f/sign_up_req",
            type: "POST",
            data: {
                mail: $("#mail").val(),
                last_name: $("#last_name").val(),
                first_name: $("#first_name").val(),
                nick_name: $("#nick_name").val(),
                prefecture: $("#prefecture").val(),
                gender: $("input[name='gender']:checked").val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data.ret == 0) {
                    location.href = "/myshiru/success?f=2";
                } else if (data.ret == 1) {
                    alert("会員登録できません\n※ このメールアドレスは既に登録されています");
                    $("#sign_up").css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $("#sign_up").css('pointer-events', 'auto');
            })
    });

    //############################################################
    // ハンバーガーメニュー
    //############################################################
    if ($('.menu_bar_sp')[0]) {
        $('.menu_bar_sp').on('click', function () {
            $('.nav-bar')[0].classList.toggle('toggle');
            $(this).find('i').toggleClass('fa fa-lg fa-bars fa fa-times');
        });
    }

    //############################################################
    // スマホのメニュが画面内になければ、メニューまでスクロール
    //############################################################
    if ($('.a')[0]) {
        let a_position = $('.a').width() + $('.a').offset().left;
        let contents_position = $(".contents").width() + $(".contents").scrollLeft();
        if ((contents_position - a_position) < 0) {
            $(".contents").animate({scrollLeft: a_position}, "slow", "swing");
        }
    }

    //############################################################
    // スマホ：もっと詳しく探す
    //############################################################
    $('.search_title').on('click', function () {
        // リストページ検索
        if ($(".list .search_form")[0]) {
            $(".list .search_form").slideToggle(300);
        }
        // ニュースページ検索
        if ($(".news_search_box .search_form")[0]) {
            $(".news_search_box .search_form").slideToggle(300);
        }
        $(this).find('i').toggleClass('fa fa-lg fa-plus fa fa-lg fa-minus');
    });

    //############################################################
    // スマホ：全国のサロン
    //############################################################
    $('.area').on('click', function () {
        let area_detail = $(this).next('.area_detail');
        area_detail[0].classList.toggle('toggle');
        $(this).find('i').toggleClass('fa fa-lg fa-plus fa fa-lg fa-minus');
    });

    //#############################################
    // Andoridの場合のみ
    //#############################################
    if (navigator.userAgent.indexOf('Android') > 0) {
        let body = document.getElementsByTagName('body')[0];
        body.classList.add('Android');
    }

    //#############################################
    // reservation_schedule
    // スクロール時にテーブル上部を固定
    //#############################################
    if ($('#reservation-schedule-wrapper').length) {
        const target = document.querySelector('#io-target');
        let dateWrapperHeight = $('.date-wrapper .date:nth-child(2) div:first-of-type').outerHeight();
        let dateWrapperWidth = $('.date-wrapper .date:nth-child(2) div:first-of-type').outerWidth();
        let weekWrapperHeight = $('.week-wrapper').height() + 10;
        let timeFirst = $('.time:first-of-type');
        timeFirst.css("width", timeFirst.outerWidth());

        // スマホの場合はヘッダーを考慮した数値を追加
        let smpHeaderHeight = 0;
        if ($('.page_header_sp').length) smpHeaderHeight = $('.page_header_sp').outerHeight();
        let smpPadding = 0;
        if (smpHeaderHeight > 0) smpPadding = 10;

        timeFirst.innerHeight(dateWrapperHeight);

        let changeElements = $('.date div:first-of-type');
        const options = {
            root: null,
            rootMargin: 0 - smpHeaderHeight + "px",
            threshold: 0
        };
        const callback = function (entry) {
            if (entry[0].isIntersecting) {
                // 画面内に表示されている場合
                $('.week-wrapper').removeClass('d-fixed');
                $(changeElements).removeClass('d-fixed');
                $(timeFirst).removeClass('d-fixed');
                $('.date-wrapper').css("padding-top", 0);
            } else {
                // 画面外に行った場合
                $('.week-wrapper').addClass('d-fixed');
                $(changeElements).addClass('d-fixed');
                $(timeFirst).addClass('d-fixed');
                $('.week-wrapper.d-fixed').css("top", smpHeaderHeight);
                $('.date-wrapper .d-fixed').css("top", weekWrapperHeight + smpHeaderHeight + smpPadding);
                $('.date-wrapper .date .d-fixed').css("width", dateWrapperWidth);
                $('.date-wrapper').css("padding-top", weekWrapperHeight + dateWrapperHeight + smpPadding);
            }
        }
        const observer = new IntersectionObserver(callback, options);
        observer.observe(target);
    }

    //#############################################
    // reservation_confirm
    // エンドユーザーの予約確定
    //#############################################
    if ($('#reservation-confirm-wrapper').length) {
        let maxLength = 200;

        // 次へボタンの設定 ********
        $('.pages .page').each(function (i, e) {
            $(this).attr('page-id', i);
        });
        $('.next-page').on('click', function () {
            let index = Number($(this).closest(".page").attr('page-id'));

            // バリデーション
            if (index == 0) {
                if ($('#answer').length) {
                    if ($('#answer').val() === "") {
                        alert("サロンからの質問にご回答ください");
                        $(window).scrollTop($('#answer').closest('td').offset().top);
                        return false;
                    } else if ($('#answer').val().length > maxLength) {
                        alert("サロンからの質問には、200文字以内でご回答ください");
                        $(window).scrollTop($('#answer').closest('td').offset().top);
                        return false;
                    }
                }
                if ($('#agree-attention').length && !$('#agree-attention:checked').length) {
                    alert('注意事項をご確認の上、「確認しました」にチェックを入れてください。');
                    $(window).scrollTop($('#agree-attention').closest('td').offset().top);
                    return false;
                }
                if ($('#agree-policy').length && !$('#agree-policy:checked').length) {
                    alert('キャンセルポリシーをご確認の上、「確認しました」にチェックを入れてください。');
                    $(window).scrollTop($('#agree-policy').closest('td').offset().top);
                    return false;
                }
                if ($('#text').val().length > maxLength) {
                    alert("ご要望・ご相談欄は、200文字以内でご記入ください");
                    $(window).scrollTop($('#text').closest('td').offset().top);
                    return false;
                }
                $('#answer-str').text($('#answer').val());
                $('#text-str').text($('#text').val());
            }

            $(".page[page-id=" + index + "]").fadeOut(0);
            $(".page[page-id=" + (index + 1) + "]").fadeIn(360);
            $(window).scrollTop(top);
        });

        // 戻るボタン
        $('.prev-page').on('click', function () {
            let index = Number($(this).closest(".page").attr('page-id'));
            $(".page[page-id=" + index + "]").fadeOut(0);
            $(".page[page-id=" + (index - 1) + "]").fadeIn(360);
        });

        // textareaの残り文字数
        $('#text').on('input', function () {
            let length = maxLength - $(this).val().length;
            let countElm = $('#length-text');
            countElm.text(length);
            length < 0 ? countElm.addClass('text-red') : countElm.removeClass('text-red');
        });
        $('#answer').on('input', function () {
            let length = maxLength - $(this).val().length;
            let countElm = $('#length-answer');
            countElm.text(length);
            length < 0 ? countElm.addClass('text-red') : countElm.removeClass('text-red');
        });
    }

    if ($('#reservation-confirm-wrapper').length || $('#messages').length) {
        // 利用ポイントの設定 ********
        // * ラジオボタンの変更イベント。トークルームへの動的追加に備えて、カスタムイベントに切り分けする。
        $('input[name="point"]').off().on('change', function (e) {
            $('body').trigger('selectUsePoint', $(this));
        });

        $('body').on('selectUsePoint', function (event, caller) {
            let servicePrice = Number($('#service-price').attr('data-price'));
            let pointBalance = Number($('#point-balance').attr('data-balance'));

            switch ($(caller).val()) {
                case "point-use":
                    $('#point-use-amount').trigger('change').focus();
                    break;

                case "point-use-all":
                    if( servicePrice >= pointBalance ){
                        $('#point-use-amount').val(pointBalance).trigger('change');
                    }else{
                        $('#point-use-amount').val(servicePrice).trigger('change');
                    }
                    $('#point-use-all').prop('checked',true)
                    break;

                case "point-not-use":
                    $('#point-use-amount').val(0).trigger('change');
                    $('#point-not-use').prop('checked',true)
                    break;
            }
        });

        // * 利用ポイントの変更イベント。トークルームへの動的追加に備えて、カスタムイベントに切り分けする。
        $('#point-use-amount').off().on('change input', function (e) {
            $('body').trigger('updateUsePoint', $(this));
        });
        $('body').on('updateUsePoint', function (e, caller) {
            // 0以下は禁止
            if ($(caller).val() == "" || $(caller).val() < 0) {
                $(caller).val(0);
            }else if ($(caller).val().slice(0,1) == 0 && $(caller).val().length > 1) {
                // 1以上が入力されたら先頭の0を消す
                $(caller).val($(caller).val().slice(1));
            }
            // 利用ポイントが残高を超えていたら残高に合わせる
            let pointBalance = Number($('#point-balance').attr('data-balance'));
            if (pointBalance < $(caller).val()) {
                $(caller).val(pointBalance);
            }
            // サービス料金を超えていたらサービス料金に合わせる
            let servicePrice = Number($('#service-price').attr('data-price'));
            if (servicePrice < $(caller).val()) {
                $(caller).val(servicePrice);
            }
            // ポイント利用額と合計金額を更新
            if ($(caller).val() == 0) {
                $('#use-point').text("利用しない");
            }else{
                let usePoint = Number($(caller).val());
                $('#use-point').text(usePoint.toLocaleString() + "ポイント");
            }
            let paymentPrice = servicePrice - $(caller).val();
            $('#payment-price').text(paymentPrice.toLocaleString() + "円(税込)");
            // 一部利用のラジオボタンをONにする
            $('#point-use').prop('checked',true)
        });
    }

    // 予約完了ボタン ********
    $('#update-reservation-confirm').on('click', function () {
        $("#update-reservation-confirm").css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/f/update_reservation_confirm",
            type: "POST",
            data: {
                salon_id: $("#salon-id").val(),
                counselor_id: $('#counselor-id').val(),
                service_id: $('#service-id').val(),
                date: $('#date').val(),
                time: $('#time').val(),
                text: $('#text').val(),
                first_flg: $('#first:checked').length ? 1 : 0,
                msg_flg: $('#message:checked').length ? 1 : 0,
                agree_policy: $('#agree-policy').length ? true : false,
                agree_attention: $('#agree-attention').length ? true : false,
                answer: $('#answer').length ? $('#answer').val() : "",
                use_point: $("#point-use-amount").length? $("#point-use-amount").val() : 0,
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data['result'] == 'success') {
                    location.replace(location.origin + '/myshiru/reservation_detail?id=' + data['id']);
                }
                else if(data['result'] == 'payment') {
                    location.href = '/myshiru/payment?id=' + data['id'];
                }
                else {
                    alert('該当の時間でご予約ができませんでした。お手数ですが、時間を再選択してください。');
                    location.replace(location.origin + '/myshiru/reservation_schedule' + location.search);
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
            });
    });


    //#############################################
    // トークルームを利用する鑑定の、依頼時確認ダイアログ
    //#############################################
    $('.chat-confirm').on('click', function () {
        if (!confirm('鑑定士とのトークルームに入室して、鑑定を依頼します。よろしいですか？')) {
            return false;
        }
        location.href = $(this).attr('data-url');
    })


    //############################################################
    // twilio web clientのトークン認証、device登録、イベント登録
    // chat_room/reservation
    //############################################################
    let durationInterval;
    if ( $('#call-incoming-wrapper').length && $('#service-type').val() === 'is-instant-telephone' ) {
        let call; // twilio device.call オブジェクト

        // twilio device オブジェクトの登録。トークンの認証。
        // ユーザーのインタラクションに合わせてインスタンス処理を行わないと、オーディオ関連がブラウザ規制で弾かれることがあるので注意。
        const device = new Device( $("#token").val() );
        device.register();

        // # ボタンイベントの登録
        // ## 応答ボタンのイベント. acceptの処理が長いため、非同期で実行。
        $('#button-call-accept').on('click', function (){
            $('#button-call-accept').css('pointer-events', 'none').attr('class', 'connecting');
            $('#call-accept-text').text('接続中です、このままお待ちください。');
            // ActionCableのソケットに再接続するイベントをトリガー
            try {$('#event-caller-ws-disconnect').click();}catch (e) {console.log(e);}

            setTimeout(function(){
                call.accept();
            }, 100);
        });

        // ## 通話終了ボタンのイベント
        let func_call_hang_up = function(){
            device.disconnectAll();
        }
        $("#button-call-hang-up").on('click', function(){
            confirmModal('show', '通話を終了しますか？', func_call_hang_up);
        });


        // # twilio device イベントの登録
        // ## 接続開始時のイベント
        device.on('registered', function(){
            console.log('instants registered');
            // オーディオの設定
            // console.log(device.audio);
            device.audio.disconnect(true);
            device.audio.incoming(true);
            device.audio.outgoing(true);
        });

        // ## 接続破棄時のイベント
        device.on('unregistered', () => {
            console.log('unregistered');
        });

        // ## 着信時のイベント
        device.on('incoming', incomingCall => {
            // 着信画面の表示
            $('#call-incoming-wrapper').css('display', 'flex');
            console.log('call incoming');
            call = incomingCall;

            // # 通話応答時のイベントを設定
            call.on('accept', acceptCall => {
                // 通話画面の表示
                $('#call-incoming-wrapper').css('display', 'none');
                $('#call-accept-wrapper').css('display', 'flex');
                console.log('call accepted');

                let params = call.customParameters;
                if ( params.has("available_time")){
                    // 時間制限が指定されている場合は取得してインターバルをカウントダウンでセット
                    let availableTime = params.get("available_time");
                    setCallDurationTimer(Number(availableTime), 'sub');

                }else{
                    // 時間制限が指定されていない場合は、0からカウントアップ
                    setCallDurationTimer(0, 'add');
                }
            });

            // # 通話終了時のイベントを設定（応答前の切断含む）
            call.on('disconnect', disconnectCall => {
                console.log('call disconnected');
                $('#call-incoming-wrapper').css('display', 'none');
                $('#call-accept-wrapper').css('display', 'none');
                clearInterval(durationInterval);
                $('#button-call-accept').css('pointer-events', 'auto').attr('class', 'incoming');
                $('#call-accept-text').text('応答');
                // ActionCableのソケットに再接続するイベントをトリガー
                try {$('#event-caller-ws-connect').click();}catch (e) {console.log(e);}
                confirmModal('hide');
            });

            // # 通話キャンセル（応答前の切断）
            call.on('cancel', cancelCall => {
                console.log('call canceled');
                $('#call-incoming-wrapper').css('display', 'none');
                $('#call-accept-wrapper').css('display', 'none');
                clearInterval(durationInterval);
                $('#button-call-accept').css('pointer-events', 'auto').attr('class', 'incoming');
                $('#call-accept-text').text('応答');
                // ActionCableのソケットに再接続するイベントをトリガー
                try {$('#event-caller-ws-connect').click();}catch (e) {console.log(e);}
                confirmModal('hide');
            });

            // # エラー時のイベント
            call.on('error', (error) => {
                alert('通話時にエラーが発生しました。時間をおいても解消しない場合は、マイシルにお問合せください。');
                console.log('[エラー] : ', error);
                clearInterval(durationInterval);
                $('#button-call-accept').css('pointer-events', 'auto').attr('class', 'incoming');
                $('#call-accept-text').text('応答');
            });
        });

        // 通話時間のタイマー設定
        function setCallDurationTimer(seconds, mode) {
            durationInterval = setInterval(function () {
                // カウントダウン or カウントアップ の設定に応じて計算
                switch (mode) {
                    case 'add':
                        seconds += 1;
                        break;

                    case 'sub':
                        if (0 < seconds) {
                            seconds -= 1;
                        }else{
                            seconds = 0;
                            clearInterval(durationInterval);
                        }
                        break;

                    default:
                        console.log('タイマーの設定に失敗しました。');
                }

                // 秒数を MM:SS に変換して表示
                // ex: 65秒 → 01:05
                let minute = Math.floor(seconds / 60);
                minute = minute < 10 ? '0' + minute : minute;
                let second = seconds % 60;
                second = second < 10 ? '0' + second : second;

                $('#call-duration').text(minute + ":" + second);
            }, 1000);
        }
    }

    //############################################################
    // トークルームの操作
    // chat_room/reservation
    //############################################################

    // メッセージ入力欄がフォーカスされたら、アクションボタンを非表示にする
    $('#send-chat-text').on('focus', function(){
        $('#chat-action-buttons').addClass('hidden');
    });
    // メッセージ入力欄からフォーカスが外れたら、アクションボタンを表示する
    $('#send-chat-text').on('blur', function(){
        $('#chat-action-buttons').removeClass('hidden');
    });

    // アクションメニューの設定
    if ($('#chat-action-menu').length) {
        // #chat-action-menu を、 #modal-body へ移動
        $('#chat-action-menu').appendTo('#modal-body');

        $('#chat-action-button').on('click', function () {
            $('#modal-title').text("トークルーム設定");
            $('#chat-action-menu').css('display', 'block');
            $('#menus').css('display', 'block');
            $('#select-line').css('display', 'none');
            $('.verify-modal-number,.verify-modal-code').css('display', 'none');
            modal('show');
        });

        $('#button-select-line').on('click', function () {
            $('#menus').css('display', 'none');
            $('#select-line').css('display', 'block');
        });

        // 認証モーダルの挙動変更
        $('.twilio-sms-verify').on('click', function() {
            $('#chat-action-menu').css('display', 'none');
            $('#select-line').css('display', 'none');
        });
    }


    // トークルームのタブ切り替えするスライドアニメーション設定
    // チャットタブを表示
    $('#room-contents-tab-show').on('click', function () {
        $('#room-contents-tab-show').addClass('a');
        $('#room-reservation-tab-show').removeClass('a');
        $('#room-contents-wrapper').addClass('active');
        $('#room-reservation-wrapper').removeClass('active');
    });
    // 取引履歴タブを表示
    $('#room-reservation-tab-show').on('click', function () {
        $('#room-contents-tab-show').removeClass('a');
        $('#room-reservation-tab-show').addClass('a');
        $('#room-contents-wrapper').removeClass('active');
        $('#room-reservation-wrapper').addClass('active');
    });


    // 決済方法ラジオボタン選択時の表記変更
    $('input[name="select-payment"]').off().on('change', function () {
        $('#messages').trigger('paymentSelect', $(this));
    });
    $('#messages').on('paymentSelect', function (ev, caller) {
        let selectPayment = $('input[name="select-payment"]:checked').val();
        switch (selectPayment) {
            case 'prepaid':
                $('#deferred-panel-wrapper').fadeOut(0);
                $('#prepaid-panel-wrapper').fadeIn(100);
                break;

            case 'deferred':
                $('#prepaid-panel-wrapper').fadeOut(0);
                $('#deferred-panel-wrapper').fadeIn(100);
                break;
        }
        scrollToElement('.select-payments-wrapper');
    });
    // 指定したクエリの要素までスクロールする
    function scrollToElement(elmentQuery) {
        let element = $(elmentQuery);
        let top = element.offset().top;
        let headerSize = 100;
        let position = top - headerSize;
        $('html, body').animate(
            { scrollTop: position }, 'slow'
        );
    }


    // トークルームから決済手続き
    $('.payment-request').off().on('click', function () {
        $('#messages').trigger('paymentRequest', $(this));
    });
    $('#messages').on('paymentRequest', function (ev, caller) {
        $(caller).css('pointer-events', 'none');
        let point = $('#point-use-amount').val();
        if ($('#point-not-use').prop('checked')) {
            point = 0;
        }
        let selectPayment = $('input[name="select-payment"]:checked').val();
        if (selectPayment === "prepaid") {
            point = 0;
        }

        $.ajax({
            url: '/myshiru/f/payment_request',
            type: 'POST',
            data: {
                room_id: $('#room-id').val(),
                room_secret: $('#secret').val(),
                reservation_id: $('#reservation-id').val(),
                use_point: point,
                select_payment: selectPayment
            }
        })
            .done(function (data) {
                if(data['result'] === 'success') {
                    if(data['url'] === "refresh"){
                        location.reload();
                    }else{
                        // 決済画面へ遷移
                        window.location.href = data['url'];
                    }
                    $(caller).css('pointer-events', 'auto');
                }else if(data['cause']==undefined){
                    // 全額ポイント支払いの場合はリロード
                    window.location.reload();
                    $(caller).css('pointer-events', 'auto');
                } else {
                    alert(data['cause']);
                    $(caller).css('pointer-events', 'auto');
                }
            })
            .fail(function () {
                alert(ERROR_MSG);
                $(caller).css('pointer-events', 'auto');
            })
    });

    // レビュー投稿モーダルの表示イベント
    // #messagesのカスタムイベントにも設定することで、ボタンの動的生成でイベントをリセットした後でも呼び出しやすいようにしている。
    $('#open-review-modal').off().on('click', function() {
        $('#messages').trigger('open-review-modal', $(this));
    });
    $('#messages').on('open-review-modal', function () {
        $('#post-review-wrapper').css('display', 'flex');
    });
    $('#post-review-wrapper .close').on('click', function () {
        $('#post-review-wrapper').css('display', 'none');
    })

    // トークルームのレビュー投稿ボタン
    // レビューの投稿
    $('#button-post-review').on('click', function () {
        let elm = $(this);
        // バリデーション
        // #review_titleは必須、かつ40文字以内
        if ($('#review_title').val().trim() == '') {
            alert('タイトルは必須です。');
            return false;
        }
        if ($('#review_title').val().length > 40) {
            alert('タイトルは40文字以内で入力してください。');
            return false;
        }
        // #commentは必須、かつ1000文字以内
        if ($('#comment').val().trim() == '') {
            alert('内容は必須です。');
            return false;
        }
        if ($('#comment').val().length > 1000) {
            alert('内容は1000文字以内で入力してください。');
            return false;
        }
        elm.css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/chat_room/create_review",
            type: "POST",
            data: {
                room_id: $('#room-id').val(),
                secret: $('#secret').val(),
                review_title: $('#review_title').val(),
                comment: $('#comment').val(),
                score1: $('#score1').val(),
                score2: $('#score2').val(),
                score3: $('#score3').val(),
                score4: $('#score4').val(),
            }
        })
            .done(function (data) {
                if (data['ret']=="success"){
                    alert('レビューを投稿しました!');
                    location.reload();
                }else{
                    elm.css('pointer-events', 'auto');
                    alert(ERROR_MSG);
                }
            })
            .fail(function () {
                elm.css('pointer-events', 'auto');
                alert(ERROR_MSG);
            })
    });

    // トークルームのレビュー投稿パネル操作
    // キャンセルボタン
    $('#button-post-review-cancel').on('click', function () {
        $('#post-review-wrapper').fadeOut(300);
    });


    //############################################################
    // トークルームの予約キャンセルイベント
    // #messagesのカスタムイベントにも設定することで、ボタンの動的生成でイベントをリセットした後でも呼び出しやすいようにしている。
    //############################################################
    $('.button-cancel-reservation').off().on('click', function() {
        $('#messages').trigger('reservation_cancel', $(this));
    });
    $('#messages').on('reservation_cancel', function(e, caller){
        if (!confirm('トークルームをキャンセルしますか？')) {
            return false;
        }
        loadingModal('show');
        let elm = $(caller);
        elm.css('pointer-events', 'none');
        $.ajax({
            url: "/myshiru/chat_room/reservation_cancel",
            type: "POST",
            data: {
                room_id: $('#room-id').val(),
                room_secret: $('#room-secret').val()
            },
            dataType: "json"
        })
            .done(function (data) {
                if (data['ret'] == 'success') {
                    location.href = "/myshiru/";
                }else{
                    location.href = "/myshiru/";
                }
            })
            .fail(function () {
                location.href = "/myshiru/";
            })
    });

    //############################################################
    // トークルームの画像アップロードイベント
    //############################################################
    if ($('#messages').length) {
        // * 画像の選択とバリデーション
        const imageInput = document.getElementById('image-input');
        const imagePreview = document.getElementById('image-preview');
        $('#image-upload-button').on('click', function () {
            imageInput.click();
        });

        imageInput.addEventListener('change', async (event) => {
            loadingModal('show');
            let file = event.target.files[0];
            let fileName = file.name;
            let fileExtension = fileName.substring(fileName.lastIndexOf('.') + 1).toLowerCase();
            // 拡張子がない場合はnoneとしてOKにする
            if (fileName.lastIndexOf('.') < 0) { fileExtension = 'none'; }
            let allowedExtensions = ['none', 'jpg', 'jpeg', 'png', 'gif', 'heic', 'heif', 'tiff', 'tif'];

            //バリデーション
            if (allowedExtensions.indexOf(fileExtension) === -1) {
                alert('無効なファイル形式です。有効な形式は jpg、jpeg、png、gif、heic、heif、tif、tiff です。');
                loadingModal('hide');
                return;
            }
            if (file.size > (50 * 1024 * 1024)) {
                alert('ファイルサイズが大きすぎます。50MB以下のファイルを選択してください。');
                loadingModal('hide');
                return;
            }


            if (fileExtension === 'heic' || fileExtension === 'heif') {
                // HEIC,HEIFの場合、heic2anyを使ってpngに変換する
                file = await heic2any({
                    blob: file,
                    toType: 'image/png',
                });
                if (file) {
                    displayImagePreview(file);
                } else {
                    alert('画像の変換に失敗しました。');
                    loadingModal('hide');
                    return;
                }

            } else if (fileExtension === 'tiff' || fileExtension === 'tif') {
                // tif,tiffの場合、tiff.jsを使ってpngに変換する
                try {
                    file = await convertTiffToPng(file);
                    if (file) {
                        displayImagePreview(file);
                    } else {
                        alert('画像の変換に失敗しました。');
                        loadingModal('hide');
                        return;
                    }
                } catch (error) {
                    alert('画像の変換に失敗しました。');
                    loadingModal('hide');
                    return;
                }

            } else {
                // 通常の画像形式なら、アップした画像をプレビュー表示
                displayImagePreview(file);
            }
            loadingModal('hide');
        });

        // プレビューモーダルの処理
        function displayImagePreview(file) {
            const reader = new FileReader();
            reader.addEventListener('load', (event) => {
                imagePreview.src = event.target.result;
            });
            reader.readAsDataURL(file);
            $('#image-preview-wrapper').css('display', 'flex');
        }

        // * 画像のアップロード
        $('#chat-room-image-upload').off().on('click', function(event) {
            console.log('uploading');
            loadingModal('show');
            $(this).css('pointer-events', 'none');
            $(this).text('送信中...');
            setTimeout(function() {
                $.ajax({
                    url: '/myshiru/chat_room/image_upload/',
                    type: 'POST',
                    data: {
                        'image': imagePreview.src,
                        'file_name': $('#image-input').val(),
                        'room_id': $('#room-id').val(),
                        'room_secret': $('#room-secret').val()
                    }
                })
                    .done(function(data) {
                        if (data['result'] == 'success') {
                            // inputの値を空にする
                            document.getElementById('image-input').value = '';
                            document.getElementById('image-preview').src = '';
                        } else {
                            alert(data['cause']);
                        }
                    })
                    .fail(function() {
                        alert(ERROR_MSG);
                    })
                $('#image-preview-wrapper').css('display', 'none');
                loadingModal('hide');
                $('#chat-room-image-upload').css('pointer-events', 'auto').text('送信');
            }, 200);
        });

        // * アップロードキャンセル
        $('#chat-room-image-upload-cancel').on('click', function() {
            $('#image-preview-wrapper').css('display', 'none');
            document.getElementById('image-input').value = '';
            document.getElementById('image-preview').src = '';
        });
    }

    // method
    // tiffをpngに変換する
    function convertTiffToPng(file) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = (event) => {
                const arrayBuffer = event.target.result;
                const tiffImage = new Tiff({ buffer: arrayBuffer });
                const canvas = tiffImage.toCanvas();

                canvas.toBlob((blob) => {
                    const convertedFile = new File([blob], file.name, { type: 'image/png' });
                    resolve(convertedFile);
                }, 'image/png');
            };
            reader.onerror = (event) => {
                reject(event.target.error);
            };
            reader.readAsArrayBuffer(file);
        });
    }
    //############################################################
    // 画像ポップアップイベント
    //############################################################
    $('#messages').on('popupImage', function(e, caller){
        $('#popup-image').attr('src', $(caller).attr('src'));
        $('#popup-image-wrapper').css('display', 'flex');
    })
    $('#popup-image-close').on('click', function(){
        $('#popup-image-wrapper').css('display', 'none');
        $('#popup-image').attr('src', "");
    });

    //############################################################
    // 電話番号のSMS認証
    //############################################################
    // キャンペーン画面の処理
    $('.enter-verify').off().on('click', function(){
        $('.notice-sms-verify').fadeOut(0);
        $('#modal-title').text("電話番号の登録");
        $('.verify-modal-number').fadeIn(100);
    });

    // 電話番号入力モーダルの表示
    $('.twilio-sms-verify').on('click', function(){
        $('#modal-title').text("電話番号の登録");
        $('.verify-send-code').text('SMSに認証コードを送信');
        $('.verify-check-code').text('認証');
        $('.verify-modal-number').fadeIn(0);
        $('.verify-modal-code').fadeOut(0);
        $('#modal-background').addClass('is-show');
        $('#modal-close-button').off().on('click', function(){
            $('#modal-background').removeClass('is-show');
        });
    });
    $('.verify-send-code').on('click', function(){
        let defaultText = "SMSに認証コードを送信";
        let caller = $(this);
        caller.css('pointer-events', 'none');
        caller.text('SMS送信中...');
        let phoneNumber = $('#verify-input-number').val();
        // ハイフンを削除. 全角数字を半角数字に変換
        phoneNumber = phoneNumber.replace(/-/g, '');
        phoneNumber = phoneNumber.replace(/[０-９]/g, function(s) {
            return String.fromCharCode(s.charCodeAt(0) - 65248);
        });
        if (phoneNumber.trim()==='') {
            alert('電話番号を入力してください');
            caller.css('pointer-events', 'auto');
            caller.text(defaultText);
            return false;
        }

        // twilio_verify_request へPOST
        $.ajax({
            url: '/myshiru/user/twilio_verify_request',
            type: 'POST',
            data: {
                phone_number: phoneNumber
            }
        })
            .done(function(data){
                if (data['ret'] == 'success') {
                    $('.verify-modal-number').fadeOut(0);
                    $('.verify-modal-code').fadeIn(100);
                    caller.css('pointer-events', 'auto');
                    caller.text(defaultText);
                }else{
                    alert(data['message']);
                    caller.css('pointer-events', 'auto');
                    caller.text(defaultText);
                }
            })
            .fail(function(){
                alert(ERROR_MSG);
                caller.css('pointer-events', 'auto');
                caller.text(defaultText);
            });
    });
    // キャンセルボタンの設定
    $('.verify-cancel').on('click', function(){
        if (location.pathname === '/myshiru/login' || '/myshiru/f/login') {
            // ログイン画面での操作なら、マイページへ
            location.href = "/myshiru/user/index";
        }else{
            // それ以外なら、モーダルを閉じる
            $('#modal-background').removeClass('is-show');
        }
    });
    // 認証コード入力モーダルの操作
    $('.verify-check-code').on('click', function(){
        let defaultText = "認証";
        let caller = $(this);
        caller.css('pointer-events', 'none');
        caller.text('認証中...');

        let verifyCode = $('#verify-input-code').val().trim();
        if (verifyCode==='') {
            alert('認証コードを入力してください');
            caller.css('pointer-events', 'auto');
            caller.text(defaultText);
            return false;
        }
        // twilio_verify_check へPOST
        $.ajax({
            url: '/myshiru/user/twilio_verify_check',
            type: 'POST',
            data: {
                phone_number: $('#verify-input-number').val(),
                verify_code: verifyCode
            }
        })
            .done(function(data){
                if (data['ret'] == 'success') {
                    alert('認証に成功しました。電話番号の登録が完了しました。');
                    location.reload();
                }else{
                    alert(data['message']);
                    caller.css('pointer-events', 'auto');
                    caller.text(defaultText);
                }
            })
            .fail(function(){
                alert(ERROR_MSG);
                caller.css('pointer-events', 'auto');
                caller.text(defaultText);
            });
    });
    $('.verify-back-number').on('click', function(){
        $('.verify-modal-code').fadeOut(0);
        $('.verify-modal-number').fadeIn(100);
    });

    //############################################################
    // トークルームで使用回線の切り替えイベント
    // select-use-line と、select-use-line-copy は同期させる
    //############################################################
    $('input[name="select-use-line"], input[name="select-use-line-copy"]').on('change', function(){
        $('#messages').trigger('changeUseLine', $(this));
    });
    $('#messages').on('changeUseLine', function(e, caller){
        let line = "";
        caller = $(caller);
        if (caller.val() === "net") {
            $('#use-net-line').prop('checked', true);
            $('#use-net-line-copy').prop('checked', true);
            line = "net";
        }else{
            $('#use-phone-line').prop('checked', true);
            $('#use-phone-line-copy').prop('checked', true);
            line = "phone";
        }
        $.ajax({
            url: '/myshiru/chat_room/change_use_line/',
            type: 'POST',
            data: {
                'line_type': line,
            }
        })
            .done(function(data) {
                if (data['result'] === 'success') {
                    if (line === "net") {
                        alert('通話時の回線を「ネット回線」に切り替えました。');
                        $('.use-phone-line-help').removeClass('show');
                    }else{
                        alert('通話時の回線を「電話回線」に切り替えました。');
                        $('.use-phone-line-help').addClass('show');
                    }
                }else{
                    alert(data['cause']);
                    // 変更前の状態に戻す
                    if (line === "net") {
                        $('#use-phone-line').prop('checked', true);
                        $('#use-phone-line-copy').prop('checked', true);
                    }else{
                        $('#use-net-line').prop('checked', true);
                        $('#use-net-line-copy').prop('checked', true);
                    }
                }
            })
            .fail(function() {
                alert(ERROR_MSG);
            });
    });


    //############################################################
    // 半角数字のみ入力可能にする(inputイベントから呼び出す)
    //############################################################
    function ValidNumberOnly(event) {
        let value = $(event.currentTarget).val();
        value = value
            .replace(/[０-９]/g, function (s) {
                return String.fromCharCode(s.charCodeAt(0) - 65248);
            })
            .replace(/[^0-9]/g, '');
        $(event.currentTarget).val(value);
    }

    //############################################################
    // 日付から予約する機能
    // reserve_from_date
    //############################################################
    if ($('#reserve-from-dates').length) {
        // カレンダークリック時
        $('.can-reserve-from-date').on('click', function () {
            let date = $(this).attr('date');
            let salonId = $('#salon_id').val();
            location.href = '/myshiru/reservation_schedule_date?salon_id=' + salonId + '&date=' + date;
        });
    }
    // カレンダーの月切り替え
    if ( $('#reserve-from-dates .month').length ){
        let months = $('#reserve-from-dates .month');
        $(months).each(function(i, e){
            // prev
            $(e).find('.arrow-prev').on('click', function(){
                if ( i === 0 ){ return false; }
                $(e).fadeOut(0);
                $(months[i-1]).fadeIn(0);
            });

            // next
            $(e).find('.arrow-next').on('click', function(){
                if ( i === months.length-1 ){ return false; }
                $(e).fadeOut(0);
                $(months[i+1]).fadeIn(0);
            });
        });
    }

    //############################################################
    // 決済機能
    // payment
    //############################################################
    $('#payment-wrapper').on('customStart',function(){
        loadingModal('show','決済中です。このままお待ちください。');
    });
    $('#payment-wrapper').on('customError',function(){
        loadingModal('hide');
    });


    //############################################################
    // サロンLP
    // page
    //############################################################
    // * レビュータップ時に詳細展開・折りたたみ切り替え
    $('.review_score').on('click', function(event) {
        let target = $(this).find('.detail_score');
        if ( target.css('display') === 'none' ) {
            target.fadeIn();
        }else{
            target.fadeOut();
        }

    });


    //############################################################
    // PC用のランキングをslickでスライドさせる
    // ランキング内のコンテンツ数が3以下の場合、slickの仕様で幅が細くなる.
    // その場合は、variableWidth: true にする
    //############################################################
    if ($('.panels.ranking.pc').length) {
        let rankings = $('.panels.ranking.pc');
        rankings.each(function (i, e) {
            // .panelの数をカウントし、3以下なら幅を可変にする
            let panelLength = $(e).find('.panel').length;
            let isVariableWidth = false;
            if (panelLength <= 3) {
                isVariableWidth = true;
            }
            $(e).slick({
                infinite: false,
                slidesToShow: 4,
                slidesToScroll: 3,
                variableWidth: isVariableWidth,
            });
        });
    }


    //############################################################
    // モーダル・ライトボックス関係
    // modal lightbox
    //############################################################
    //表示・非表示**************
    function modal(mode) {
        switch (mode) {
            case 'show':
                $('#modal-background').addClass('is-show');
                $('body').css('overflow-y', 'hidden');
                break;

            case 'hide':
                $('#modal-background').removeClass('is-show');
                $('body').css('overflow-y', 'auto');
                break;
        }
    }
    //背景・閉じるボタンクリック時に閉じる*************
    function modalCloseSetting() {
        $('#modal-background').on('click', function (e) {
            if (e.eventPhase === 2) modal('hide');
        });
        $('#modal-close-button').on('click', function (e) {
            if (e.eventPhase === 2) modal('hide');
        });
    }
    modalCloseSetting();

    // ローディング用モーダル **************
    function loadingModal(mode, text='') {
        switch (mode) {
            case 'show':
                if( !$('#loading-modal-background').length ){
                    $('body').append( $(
                        '<div id="loading-modal-background">' +
                        '<div id="loading-modal-spinner"></div><p>'+text+'</p>' +
                        '</div>'
                    ) );
                }
                break;
            case 'hide':
                if( $('#loading-modal-background').length ){
                    $('#loading-modal-background').remove();
                }
                break;
        }
    }

    // confirmモーダル **************
    function confirmModal(mode, text='', callback=null) {
        switch (mode) {
            case 'show':
                if( !$('#confirm-background').length ){
                    $('body').append( $(
                        `<div id="confirm-background" class="bottom">
                            <div id="confirm-wrapper">
                                <div id="confirm-inner">
                                    <div id="confirm-body">
                                        <div id="confirm-text">${text}</div>
                                        <div id="confirm-buttons">
                                            <div id="confirm-cancel">キャンセル</div>
                                            <div id="confirm-accept">OK</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>`
                    ) );
                    // イベントの設定
                    $('#confirm-cancel,#confirm-background').on('click', function(e){
                        if (e.eventPhase === 2) { confirmModal('hide'); }
                    });
                    $('#confirm-accept').on('click', function(e) {
                        confirmModal('hide');
                        callback();
                    })
                }else{
                    $('#confirm-background').css('display', 'flex');
                    $('#confirm-text').text(text);
                }
                break;

            case 'hide':
                if( $('#confirm-background').length ){
                    $('#confirm-background').remove();
                }
        }
    }

    //############################################################
    // safariのvh問題対策
    // 画面リサイズ時、CSSの--vh変数に、windowの高さを代入する
    //############################################################
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
        let vh = window.innerHeight * 0.01;
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    });

    //############################################################
    // 画像の遅延読み込み lazy loads
    // imgタグを下記のように設定することで遅延読み込み可能。
    // <img class="lozad" data-src="image.png">
    //############################################################
    const observer = lozad('.lozad', {
        loaded: function(el) {
            el.classList.remove('lozad');
        }
    });
    observer.observe();


});
